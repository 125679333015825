<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-xl-10">
        <h3>
          Benutzer
          <small class="text-muted">
            <span v-if="!requesting">{{users.length}}</span>
            <i
              class="fa fa-fw fa-spinner fa-pulse"
              v-if="requesting"
            ></i>
          </small>
        </h3>
      </div>
    </div>
    <div
      class="row"
      v-if="users.length"
    >
      <div class="col-12 col-xl-10">
        <users-table
          :currentUser="currentUser"
          :users="users"
          :roles="roles"
          :requesting="requesting"
          @toggleUserDisabled="onToggleUserDisabled"
          @updateUserRole="onUpdateUserRole"
          @deleteUser="onDeleteUser"
        ></users-table>
      </div>
      <div class="col col-xl-2">
        <div class="card">
          <div class="card-body">
            <create-user-form
              :requesting="requesting"
              @createUser="onCreateUser"
            ></create-user-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UsersTable from '@/components/users/UsersTable';
import CreateUserForm from '@/components/users/CreateUserForm';
import { functions, auth } from '../../firebase';

export default {
  name: 'users',
  components: {
    UsersTable,
    CreateUserForm
  },
  data() {
    return {
      currentUser: '',
      users: [],
      roles: [],
      requesting: false
    };
  },
  async created() {
    this.currentUser = auth.currentUser.uid;
    this.requesting = true;

    try {
      const getUsers = functions.httpsCallable('getUsers');
      const getRoles = functions.httpsCallable('getRoles');

      const [getUsersResponse, getRolesResponse] = await Promise.all([
        getUsers({}),
        getRoles()
      ]);

      this.users = getUsersResponse.data.users;
      this.roles = getRolesResponse.data;
      this.requesting = false;
    } catch (err) {
      console.error(err);

      this.requesting = false;
    }
  },
  methods: {
    async onCreateUser({ email, password }) {
      this.requesting = true;

      try {
        const createUser = functions.httpsCallable('createUser');

        const createUserResponse = await createUser({ email, password });

        this.users.push(createUserResponse.data);

        this.requesting = false;
      } catch (err) {
        console.error(err);

        this.requesting = false;
      }
    },
    async onToggleUserDisabled({ uid, disabled }) {
      this.requesting = true;

      try {
        const updateUser = functions.httpsCallable('updateUser');

        await updateUser({ uid, disabled: !disabled });

        this.users = this.users.map(user => {
          if (user.uid === uid) {
            user.disabled = !disabled;
          }

          return user;
        });
        this.requesting = false;
      } catch (err) {
        console.error(err);

        this.requesting = false;
      }
    },
    async onUpdateUserRole({ uid, role }) {
      this.requesting = true;

      try {
        const updateUserRole = functions.httpsCallable('updateUserRole');

        await updateUserRole({ uid, role });

        this.users = this.users.map(user => {
          if (user.uid === uid) {
            user.customClaims.role = role;
          }

          return user;
        });

        this.requesting = false;
      } catch (err) {
        console.error(err);

        this.requesting = false;
      }
    },
    async onDeleteUser(uid) {
      if (
        confirm('Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?')
      ) {
        this.requesting = true;

        try {
          const deleteUser = functions.httpsCallable('deleteUser');

          await deleteUser({ uid });

          this.users = this.users.filter(user => user.uid !== uid);
          this.requesting = false;
        } catch (err) {
          console.error(err);

          this.requesting = false;
        }
      }
    }
  }
};
</script>
