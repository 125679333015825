<template>
  <form @submit.prevent="onSubmit">
    <div class="form-group">
      <label for="email">E-Mail</label>
      <input type="email" class="form-control form-control-sm" required v-model="email" />
    </div>
    <div class="form-group">
      <label for="password">Passwort</label>
      <input type="password" class="form-control form-control-sm" required v-model="password" />
    </div>
    <div class="form-group mb-0">
      <button
        type="submit"
        class="btn btn-block btn-success btn-sm"
        :disabled="requesting"
      >Speichern</button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'create-user-form',
  props: {
    requesting: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    onSubmit() {
      this.$emit('createUser', { email: this.email, password: this.password });
      this.email = '';
      this.password = '';
    }
  }
};
</script>
